import React from "react";
import "./Sidepopup.css";

const Sidepopup = ({ isOpen, onClose, loanData, title }) => {
  if (!isOpen) return null;

  console.log(loanData?.prin_os, "loanData");

  return (
    <div className="side-modal-overlay">
      <div className="side-modal">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h3>{title}</h3>
        <div className="loan-details-sidePop">
          {title === "Loan Details" && (
            <>
              <div className="loan-details-field">
                <p>
                  <strong>Loan ID:</strong>
                </p>
                <p>{loanData?.bic?.loan_id}</p>
              </div>

              <div className="loan-details-field">
                <p>
                  <strong>Balance Tenure:</strong>
                </p>
                <p>
                  {loanData?.bic?.tenure + " " + loanData?.bic?.tenure_type}
                </p>
              </div>

              <div className="loan-details-field">
                <p>
                  <strong>Loan Amount:</strong>
                </p>
                <p>{loanData?.bic?.sanction_amount}</p>
              </div>

              <div className="loan-details-field">
                <p>
                  <strong>Disbursement Amount:</strong>
                </p>
                <p>{loanData?.bic?.net_disbur_amt}</p>
              </div>

              <div className="loan-details-field">
                <p>
                  <strong>EMI Amount:</strong>
                </p>
                <p>{loanData.repaymentDetails.amount}</p>
              </div>

              <div className="loan-details-field">
                Principal Outstanding Amount
                <strong>
                  {" "}
                  ₹{parseFloat(loanData?.prin_os?.$numberDecimal).toFixed(2)}
                </strong>
              </div>
            </>
          )}

          {title === "Breakup" && (
            <>
              {loanData.repaymentDetails?.charges?.interestDue > 0 && (
                <div className="brakeup-field">
                  <p>
                    <strong>Interest Due:</strong>
                  </p>
                  <p>{loanData.repaymentDetails.charges?.interestDue}</p>
                </div>
              )}

              {loanData.repaymentDetails?.charges?.principalDue > 0 && (
                <div className="brakeup-field">
                  <p>
                    <strong>Due Principle:</strong>
                  </p>
                  <p>{loanData.repaymentDetails?.charges?.principalDue}</p>
                </div>
              )}

              {loanData.repaymentDetails?.charges?.lpiDue > 0 && (
                <div className="brakeup-field">
                  <p>
                    <strong>Late Payment Interest:</strong>
                  </p>
                  <p>{loanData.repaymentDetails?.charges.lpiDue}</p>
                </div>
              )}

              {loanData.repaymentDetails?.charges?.installmentAmount > 0 && (
                <div className="brakeup-field">
                  <p>
                    <strong>Installment Amount:</strong>
                  </p>
                  <p>{loanData.repaymentDetails?.charges.installmentAmount}</p>
                </div>
              )}

              {loanData.repaymentDetails?.charges?.chargeAmount > 0 && (
                <div className="brakeup-field">
                  <p>
                    <strong>Charges:</strong>
                  </p>
                  <p>{loanData.repaymentDetails?.charges?.chargeAmount}</p>
                </div>
              )}

              <hr style={{ marginTop: "20px", borderColor: "#f3eeee" }} />

              {loanData.repaymentDetails?.charges?.excessPayment > 0 && (
                <div className="brakeup-field">
                  <p>
                    <strong>Surplus Amount:</strong>
                  </p>
                  <p>-{loanData.repaymentDetails?.charges?.excessPayment}</p>
                </div>
              )}
            </>
          )}
        </div>
          <button className="cancel-button" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Sidepopup;

