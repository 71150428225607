import React, { useEffect, useRef, useState } from "react";
import "./OtpInputPopUp.css";
import axios from "axios";

/**
 * @author Rajesh
 * @description Alert component alert the message with type as failure or success
 * @param {Object} Object {type, message, handleClose}
 * @returns OtpInputPopUp component
 */
const OtpInputPopUp = ({
    length = 6,
    setCategoryScreen,
    setModel2,
    loanId,
    mobileNumber,
    pan,
    otpCount,
    setOtpCount,
    setOpen,
    setRequestId,
    requestId,
    setAlertType,
    setAlertMessage,
    fullName,
    setFullName,
    setLoginUser

}) => {
    const [inputValues, setInputValues] = useState(Array(length).fill(""));
    const [verifySubmitSize, setVerifySubmitSize] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const intervalIdRef = useRef(null);
    const [otpSend, setOTPSend] = useState(false);
    const [otp, setOtp] = useState('');
    const [sendingOTP, setSendingOTP] = useState(false);
    const [OTPSent, setOTPSent] = useState(false);


    useEffect(() => {
        if (OTPSent) {
            startResendTimer();
        }
    }, [OTPSent]);
    useEffect(() => {
        setOTPSent(true);
        setSendingOTP(false);
    }, [])

    const checkPayload = (payload) => {
        let payloadError = false;

        let { loan_id, mob_num, pan } =
            payload;

        loan_id = loan_id.trim();
        mob_num = mob_num.trim();
        pan = pan.trim();

        if (loan_id.length === 0 || /\s/.test(loan_id)) {
            payloadError = true;
        } else if (!/^[6-9][0-9]{9}$/.test(mob_num)) {
            payloadError = true;
        } else if (pan.length === 0 || !/^[A-Z]{3}[PCHABGJFLT]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/.test(pan)) {
            payloadError = true;
        }
        return payloadError;
    };
    const sendAdharOtp = async (e) => {
        e.preventDefault();
        const payload = {
            loan_id: loanId,
            mob_num: mobileNumber,
            pan: pan,
        };
        const payloadError = checkPayload(payload);

        if (!payloadError) {
            if (otpCount >= 3) {
                setOpen(true);
                setAlertType("failure");
                setAlertMessage("Please try again later");
                return;
            }
            setSendingOTP(true);

            setTimeout(() => {
                setOTPSent(true);
                setSendingOTP(false);
            }, 3000);
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/send_otp`,
                    payload,
                    config
                );
                if (response?.data?.status === "success") {
                    const capitalizeWords = (str) => {
                        if (!str) return ''; 
                        return str
                          .trim() 
                          .split(' ') 
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
                          .join(' '); 
                    };
                        const coappName = response.data?.data?.coappName;
                        const formattedcoapName = capitalizeWords(coappName)
                        const firstName = response?.data?.data?.first_name;
                        const middleName = response?.data?.data?.middle_name;
                        const lastName = response?.data?.data?.last_name;
                        const formattedFirstName = capitalizeWords(firstName);
                        const formattedMiddleName = capitalizeWords(middleName);
                        const formattedLastName = capitalizeWords(lastName);
                        const fullName = `${formattedcoapName ? `${formattedcoapName}` : ''}${formattedFirstName ? `${formattedFirstName}` : ''}${formattedMiddleName ? ` ${formattedMiddleName}` : ''}${formattedLastName ? ` ${formattedLastName}` : ''}`;
                        setFullName(fullName);
                        const newOtpCount = otpCount + 1;
                        setOtpCount(newOtpCount);
                        setRequestId(response?.data?.data?.otp_response?.requestId);


                }
                if (response?.data?.status === "error") {
                    setOpen(true);
                    setAlertType("failure");
                    setAlertMessage("Incorrect details: please provide correct details");
                }
            } catch (error) {
                setOpen(true);
                setAlertType("failure");
                setAlertMessage("Something Went Wrong");

            }
        }
        else {
            setOpen(true);
            setAlertType("failure");
            setAlertMessage("Incorrect details: please provide correct details");
        }



    };
    const inputRefs = useRef(
        Array(length)
            .fill(null)
            .map(() => React.createRef()),
    );
    const findPreviousEmptyIndex = (currentIndex) => {
        for (let i = 0; i < currentIndex; i++) {
            if (!inputValues[i]) {
                return i;
            }
        }
        return null;
    };

    const getNextIndex = (currentIndex) => {
        for (let i = currentIndex + 1; i < length; i++) {
            if (!inputValues[i]) {
                return i;
            }
        }
        return null;
    };



    const handleInputChange = (index, value) => {
        if (/^\d$/.test(value)) { // Allow only a single-digit numeric input
            const newValues = [...inputValues];
            newValues[index] = value;
            setInputValues(newValues);
            handleOtpChange(newValues.join(""));

            // Move to the next empty input, if any
            const nextIndex = findNextEmptyIndex(index);
            if (nextIndex !== null) {
                inputRefs.current[nextIndex].current.focus();
            }
        } else if (value === "") {
            // Clear the current input and move focus to the previous input
            const newValues = [...inputValues];
            newValues[index] = "";
            setInputValues(newValues);

            // Move focus to the previous input field, if any
            const prevIndex = findPreviousFilledIndex(index);
            if (prevIndex !== null) {
                inputRefs.current[prevIndex].current.focus();
            }
        }
        setError(false);
    };

    // Helper function to find the previous filled input index
    const findPreviousFilledIndex = (currentIndex) => {
        for (let i = currentIndex - 1; i >= 0; i--) {
            if (inputValues[i] !== "") {
                return i;
            }
        }
        return null;
    };

    // Helper function to find the next empty input index
    const findNextEmptyIndex = (currentIndex) => {
        for (let i = currentIndex + 1; i < inputValues.length; i++) {
            if (inputValues[i] === "") {
                return i;
            }
        }
        return null;
    };



    const handleSubmit = async (e) => {
        if (inputValues.includes('')) {
            e.preventDefault();
            return;
        }

        const otpEntered = Number(inputValues.join(''));
        const payload = {
            request_id: requestId,
            otp: otpEntered,
            loan_id: loanId,
        }
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/validate_otp`,
                payload,
                config
            );

            if (response?.data?.status === "success") {
                setOpen(true);
                setAlertType("success");
                setAlertMessage(`Welcome ${fullName}`)
                setCategoryScreen(true);
                setLoginUser(true);
            }
            if (response?.data?.status === "error") {
                setOpen(true);
                setAlertType("failure");
                setAlertMessage("OTP is not verified");

            }
        } catch (error) {
            setError(true);
            setOpen(true);
            setAlertType("failure");
            setAlertMessage(error?.response?.data?.message ?? error.message);
        }

    }


    const handleCloseOtp = () => {
        setModel2(false);
    };

    const handleOtpChange = (value) => {
        setOtp(value);
    };
    const startResendTimer = () => {
        setResendTimer(59);
        setError(false);
        clearInputValues();

        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }

        intervalIdRef.current = setInterval(decrementTimer, 1000);
    };
    const clearInputValues = () => {
        const newValues = Array(length).fill("");
        setInputValues(newValues);
        handleOtpChange(newValues.join(""));
    };
    const decrementTimer = () => {
        setResendTimer((prevTime) => {
            if (prevTime === 1) {
                clearInterval(intervalIdRef.current);
                setOTPSent(false);
            }
            return prevTime - 1;
        });
    };


    



    return (
        <>
            <div className="modal-overlay-1">
                <div className="modal-content-1">
                    <h1 className="title" >Mobile Verification</h1>
                    <div>
                        <div className="input-container" >
                            {inputValues.map((value, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={value}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    ref={inputRefs.current[index]}
                                    autoComplete="off"
                                    className="input-box"
                                    style={{
                                        border: error ? "1px solid red" : "1px solid #CCCDD3",
                                    }}
                                    disabled={verifySubmitSize ? 'Disable Inputs' : ''}
                                />
                            ))}
                        </div>
                        {/* {error && <p style={{ color: "red", textAlign: "center", marginTop: "5px", position: "absolute", width: "100%", top: "54%", right: "0%" }}>{errorMessage}</p>} */}
                        <div className="resend-container" >
                            {sendingOTP ? (
                                <p>Resending OTP...</p>
                            ) : resendTimer > 0 && OTPSent ? (
                                <p>Resend in {resendTimer} sec</p>
                            ) : (
                                <p onClick={sendAdharOtp} className="resend-text" >
                                    Resend
                                </p>
                            )}
                        </div>

                    </div>
                    <div className="hr"></div>
                    <div className="button-group">
                        <button className="cancel-btn" onClick={handleCloseOtp}>Cancel</button>
                        <button className={`submit-btn ${!inputValues.includes('') ? "submit-btn-clickable" : ""}`} onClick={handleSubmit} >Submit</button>
                    </div>

                </div>
            </div>

        </>
    );
};



export default OtpInputPopUp;
